import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import * as Icon from "react-bootstrap-icons"

const Scanner = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const scannerData = location?.state?.scanner
    const uuidData = location?.state?.uuid
    const token = location?.state?.token
    const lang = location?.state?.lang

    return (
        <>
            <div style={{ margin: "10px" }}>
                <button onClick={() =>{navigate(`/event-details/${token}/${lang}`)}}
                    style={{ backgroundColor: "#FD6E47", color: "white", padding: "5px 10px", borderRadius: "10px", border: "none" }}>
                    <Icon.ArrowLeft className='me-2' />Back
                </button>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
                <h3 className='mb-3'>Sacanner And ID</h3>
            </div>

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <img src={scannerData} style={{ width: "200px", height: "200px" }} />
                <h3>OR</h3>
                <div style={{ marginTop: "15px" }}>
                    <h5>{uuidData}</h5>
                </div>
            </div>
        </>
    )
}

export default Scanner

