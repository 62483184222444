import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const PaymentSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const transactionId = searchParams.get('TId');

    const [token, setToken] = useState('');
    const [lang, setLang] = useState('')

    console.log("token", token);
    console.log("lang", lang);

    useEffect(() => {
        const storedToken = localStorage.getItem('myTokenKey');
        if (storedToken) {
            const parsedToken = JSON.parse(storedToken);
            const token = parsedToken.token;
            const lang = parsedToken.lang;
            setToken(token);
            setLang(lang);
        }
    }, []);

    const handleTryAgain = () => {
        localStorage.removeItem('myTokenKey');
        navigate(`/courses-details/${token}/${lang}`);
    };


    return (
        <>
            <div style={{ width: "85%", margin: "auto" }}>
                <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", marginTop: "200px", marginBottom: "200px", textAlign: "center", padding: "40px" }}>
                    <Icon.CheckCircleFill style={{ fontSize: 50, color: "green", marginBottom: "20px" }} />
                    <h1 style={{ marginBottom: "20px", color: "green", fontWeight: "600", fontSize: "35px" }}>Payment  Successful</h1>
                    <h3 style={{ color: "grey" }}>Thank You! Your payment is completed</h3>
                    <p className='fw-bold'>Transaction Id: <span>{transactionId}</span></p>
                    <Button style={{ backgroundColor: "green", marginTop: "50px" }} size="lg"
                        onClick={handleTryAgain}>
                        Done
                    </Button>
                </div>
            </div>
        </>

    )
}

export default PaymentSuccess
