import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import EventPage from './Components/Page/Event/EventPage';
import EventDetails from './Components/Page/Event/EventDetails';
import Terms from './Components/Page/Terms/Terms';
import Privacy from './Components/Page/Privacy/Privacy';
import Invoice from './Components/Page/Invoice/Invoice';
import PageNotFound from './Components/Page/PageNotFound/PageNotFound';
import PaymentFail from './Components/Page/Payment/PaymentFail';
import PaymentSuccess from './Components/Page/Payment/PaymentSuccess';
import CoursesDetails from './Components/Page/Course/CoursesDetails';
import PrimaryPage from './Components/Page/PrimaryPage/PrimaryPage';
import Scanner from './Components/Page/Event/Scanner';
import LandingPage from './Components/Page/PrimaryPage/LandingPage';
import MainLandingPage from './Components/Page/PrimaryPage/MainLandingPage';
import ContactUs from './Components/Page/ContactUs/ContactUs';
import PaymentMode from './Components/Page/Course/PaymentMode/PaymentMode';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* <Route path='/' element={<PrimaryPage />} /> */}
          {/* <Route path='/' element={<LandingPage />} /> */}
          <Route path='/' element={<MainLandingPage />} />
          {/* <Route path='/event/:token/:lang' element={<EventPage />} /> */}
          <Route path='/event-details/:token/:lang' element={<EventDetails />} />
          <Route path='/scanner' element={<Scanner />} />
          {/* <Route path='/course/:token/:lang' element={<Course />} /> */}
          {/* <Route path='/course-details' element={<CourseDetails/>} /> */}
          {/* <Route path='/courses/:token/:lang' element={<Courses/>}/> */}
          <Route path='/courses-details/:token/:lang' element={<CoursesDetails />} />
          <Route path="/courses-details/paymentmode" element={<PaymentMode />} />
          <Route path='contact-us' element={<ContactUs />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/invoice' element={<Invoice />} />
          <Route path='/paymentsuccess' element={<PaymentSuccess />} />
          <Route path='/paymentfail' element={<PaymentFail />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
