import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import { useNavigate, useLocation, useParams } from 'react-router';


const PaymentFail = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const transactionId = searchParams.get('TId');

    const [token, setToken] = useState('');
    const [lang, setLang] = useState('')

    useEffect(() => {
        const storedToken = localStorage.getItem('myTokenKey');
        if (storedToken) {
            const parsedToken = JSON.parse(storedToken);
            const token = parsedToken.token;
            const lang = parsedToken.lang;
            setToken(token);
            setLang(lang);
        }
    }, []);


    const handleTryAgain = () => {
        localStorage.removeItem('myTokenKey');
        // navigate(`/courses-details/${token}/${lang}`);
        window.location.href = `https://sfg.foxberry.live/courses-details/${token}/${lang}`;
    };

    console.log("paymnetfailtoken", token);


    return (
        <>
            <div style={{ width: "85%", margin: "auto" }}>
                <div style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", marginTop: "200px", marginBottom: "200px", textAlign: "center", padding: "40px" }}>
                    <Icon.ExclamationTriangleFill style={{ fontSize: 50, color: "red", marginBottom: "20px" }} />
                    <h1 style={{ marginBottom: "20px", color: "grey", fontWeight: "600", fontSize: "35px" }}>Transaction Failed</h1>
                    <h3 style={{ color: "grey" }}>Please try a different payment method</h3>
                    <p className='fw-bold'>Transaction Id: <span>{transactionId}</span></p>
                    <div>
                        <button type="button" class="btn btn-outline-primary" style={{ marginTop: "50px", fontSize: "15px", padding: "5px" }} size="lg"
                            onClick={handleTryAgain}
                        // onClick={() => navigate('/courses-details/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyRGF0YSI6eyJ1c2VyU3RhdGUiOiJyZWdpc3RlcmVkIiwiX2lkIjoiNjVkODIxNzlmZDIwZDkzNzg4NThiNDdkIiwibW9iaWxlIjoiMTIzNDU2Nzg5MSIsInBhc3N3b3JkIjoiJDJhJDEyJG1LendmcFFMNzMyZC5MU2NEU1hRWi5OQlFlQkRsLkpWNlk3b1AxWTg1WWJEUjdTQjNWVTZ5IiwiY3JlYXRlZEF0IjoiMjAyNC0wMi0yM1QwNDozOToyMS4yNTRaIiwidXBkYXRlZEF0IjoiMjAyNC0wMi0yM1QwNDozOToyMS4yNTRaIiwiX192IjowfSwiaWF0IjoxNzA4NjYzMTcxfQ.7q-5K5RbEFUCaVR2iowND4IVkelCcXNQM665xQDQcJQ/en')}
                        >
                            Try Again
                        </button>
                    </div>

                </div>
            </div>
        </>
    )
}

export default PaymentFail
