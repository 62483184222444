const environments = {
    master: {
        baseUrl: "https://sfg.foxberry.live/v1",
    },
    privacyterms: {
        baseUrl: "https://api.sfg.foxberry.live/v1",
    },
    eventphase1: {
        baseUrl: "https://api.sfg.foxberry.live/v1",
    }
};

export const ApiCalling = {
    base_url_v1: environments[process.env.REACT_APP_ENV].baseUrl,
    paths: {
        initiatePayment: '/pay/initiatePayment',
        getEventForUser: '/event/getEventForUser',
        bookEvent: '/event/eventRegistration',
        getLabels: '/labels/getAppLabels',
        getEvents: '/event/getEvents',
        getCourseForUser: '/course/getCourseForUser'
    },
};
