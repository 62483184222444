import React, { useRef } from 'react';
import sriimg from "../../../Assets/image 17843.png";
import familyImg from "../../../Assets/Isolation_Mode.png";
import * as Icon from "react-bootstrap-icons";
import { Button } from 'react-bootstrap';
import groupImg from "../../../Assets/Group 1707479703.png";
import familyimg from "../../../Assets/Group 1707479701.png";
import rectangle from "../../../Assets/Rectangle 4.png";
import Slider from 'react-slick';
import SliderImage from "../../../Assets/SFG.jpeg"
import Dada from "../../../Assets/SrimFrame.png"
import Shrim from "../../../Assets/PrushottamFrame.png"
import Tambe from "../../../Assets/TambeFrame.png"
import Flower from "../../../Assets/image 17844.png"
import SakalLogo from "../../../Assets/sakal-digital-2 1.png"
import Aagni from "../../../Assets/Isolation_Mode (1).png";
import Book from "../../../Assets/book.png";
import swasthyam from "../../../Assets/swasthyam.png";
import VerticalLine from "../../../Assets/Rectangle 37.png";

const MainLandingPage = () => {
    const sliderRef = useRef(null);

    const gradientStyle ={
        background: 'linear-gradient(to top, #FFD2C4, #FFEEE9, #FFFCFB)'
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // autoplay: false,
        // autoplaySpeed: 3000,
        easing: 'easeInOutQuad',
        beforeChange: (currentSlide, nextSlide) => {
            const isVideoSlide = checkIfVideoSlide(currentSlide); // Check if the current slide is a video slide
            if (isVideoSlide) {
                sliderRef.current.slickPause();
            }
        },
        afterChange: (currentSlide) => {
            const isVideoSlide = checkIfVideoSlide(currentSlide); // Check if the current slide is a video slide
            if (!isVideoSlide) {
                sliderRef.current.slickPlay();
            }
        },
        customPaging: function (i) {
            return (
                <div style={{ width: '10px', height: '10px', background: '#333', borderRadius: '50%' }}>

                </div>
            );
        },
    };

    const checkIfVideoSlide = (slideIndex) => {
        return slideIndex === 1 || slideIndex === 3;
    };

    const handleSlideClick = () => {
        sliderRef.current.slickNext();
    };

  return (
    <>
        <div style={{marginBottom:"10%"}}>
            <div style={gradientStyle}>
                <div style={{display:"flex",width:"100%", justifyContent:"space-between",alignItems:"center", padding:"30px"}}>
                    <img src={sriimg} alt='img' style={{height:"30%", width:"30%"}}/>
                    <img src={familyImg} alt='img' style={{height:"10%", width:"10%"}}/>
                    <button style={{backgroundColor:"#FD6E47", border:"none", width:"42%", borderRadius:"5px", color:"white", padding:"1%"}}>Download App<Icon.ArrowRight/></button>
                </div>

                <div style={{padding:"0% 15%"}}>
                    <h1 style={{lineHeight:"40px", textAlign:"center", fontWeight:400, fontSize:"28px"}}>Holistic Lifestyle Guidance Program</h1>
                </div>

                <div style={{marginTop:"30px",padding:"0px 20px"}}>
                    <h6 style={{fontWeight:700, textAlign:"center"}}>The Journey of Success & Self Happiness.</h6>
                    <h6 style={{textAlign:"center"}}>Often, success is co-related with happiness. Happiness is subjective and the pursuit of happiness is a complex landscape of self expectations, hard work, and material desires. However, as we journey through life, we come to realize that happiness is not a destination to reach, rather it is a way of life.</h6>
                </div>

                <div  style={{display:"flex", justifyContent:"center", marginTop:"20px", marginBottom:"30px"}}>
                    <img src={groupImg} style={{width:"60%"}}/>
                </div>

                <div>
                    <img src={familyimg} style={{width:"100%"}}/>
                </div>
            </div>

            <div style={{backgroundColor:"#FFF8F6"}}>
                <p style={{fontWeight:400,fontSize:"18px",textAlign:"center", paddingTop:"38px"}}>PILLARS OF SRI FAMILY GUIDE</p>
                <div style={{display:"flex",justifyContent:"space-around", marginTop:"30px"}}>
                    <div className='images'>
                        <img src={Dada}/>
                    </div>
                    <div className='images'>
                        <img src={Tambe} />  
                    </div>
                    <div className='images'>
                        <img src={Shrim}/>
                    </div>
                </div>

                <div style={{margin:"30px"}}>
                    <p style={{textAlign:"center"}}> A recipient of Padma Shri, Dr. Balaji Tambe is an Ayurvedic Doctor, 
                    Spiritual Guide, and also an expert  in Music and Yoga Therapy. He is the founder of  
                    Atmasantulana Village, located in Pune. He is of the belief that – 
                    “Ayurveda is not merely a medical solution; instead it is a way of life to achieve healthy mind, body, and soul.”
                    </p>
                </div>

                <div style={{display:"flex",justifyContent:"center", alignItems:"center"}}>
                    <img src={Flower} style={{width:"18%", height:"15%",marginBottom:"15%"}}/>
                </div>
            </div>

            <div style={{border:"1px solid #A7A7A7",padding:"20px", borderRadius:"10px",margin:"20px", backgroundColor:"white"}}>
                <div>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <img src={SakalLogo} style={{width:"35%", height:"38%",marginRight:"30px"}}/>
                    </div>
                    
                    <div>
                        <p style={{fontSize:"14px",lineHeight:"none"}}>Esteemed as India’s distinguished media company, commitment at Sakal Media Group, 
                        extends beyond business success. With a steadfast believe in the power of business for good, 
                        Sakal Media integrates social responsibility and community wellness into the fabric of everything it undertakes.
                        </p>
                        <p style={{fontSize:"14px",lineHeight:"none"}}>From our diverse portfolio, we actively seek opportunities to uplift and empower? communities.</p>
                        <p style={{fontSize:"14px",lineHeight:"none"}}>Whether it is the ‘Family Doctor’ pustika, Arogya Sakal Expo, or Swasthyam event, 
                        we address pressing lifestyle issues and try to impact lives in a positive way. 
                        As an upswing of all these activites, we now come ahead with a holistic lifestyle guidance program –‘Sri Family Guide’.</p>
                    </div>
                </div>
            </div>

            <div style={{marginTop:"40px", marginBottom:"20px"}}>
                <p style={{textAlign:"center",fontWeight:400, fontSize:"22px"}}>Features of the Program</p>
                    <div className='mainContainer slider-wrapper' style={{ padding:"6%", borderRadius:"20px",width:"80%",margin:"5% 10%",
                            background: 'linear-gradient(148.75deg, rgba(255, 109, 63, 0.4) -23.05%, rgba(164, 136, 206, 0.01) 91.93%)' }}>
                        <Slider ref={sliderRef} {...settings}>
                        <div onClick={handleSlideClick}>
                            <h4 style={{fontWeight:600}}>Course Material</h4>
                            <span style={{fontWeight:400, fontSize:"14px", lineHeight:"0px"}}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less </span>
                            <div style={{display:"flex", justifyContent:"end"}}>
                                <img src={Book} style={{width:"20%"}}/>
                            </div>
                        </div>

                        <div onClick={handleSlideClick} >
                            <h4 style={{fontWeight:600}}>Agnihotra Kit</h4>
                            <span style={{fontWeight:400, fontSize:"14px", lineHeight:"0px"}}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less </span>
                            <div style={{display:"flex", justifyContent:"end"}}>
                                <img src={Aagni} style={{width:"20%"}}/>
                            </div>
                        </div>

                        <div onClick={handleSlideClick} >
                            <h4 style={{fontWeight:600}}>Events</h4>
                            <span style={{fontWeight:400, fontSize:"14px",marginBottom:"0px"}}>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less </span>
                            {/* <div style={{display:"flex", justifyContent:"end"}}>
                                <img src={Aagni} style={{width:"20%"}}/>
                            </div> */}
                        </div>
                    </Slider>
                </div>
            </div>

            <div style={{marginTop:"60px"}}>
                <p style={{textAlign:"center",fontWeight:400, fontSize:"22px"}}>Community Events</p>
                <div className='mainContainer slider-wrapper' style={{ margin:"20px"}}>
                    <Slider ref={sliderRef} {...settings}>
                        <div onClick={handleSlideClick}>
                            <img src={swasthyam} style={{width:"100%",height:"100%"}}/>
                        </div>

                        <div onClick={handleSlideClick}>
                            <img src={swasthyam} style={{width:"100%",height:"100%"}}/>
                        </div>

                        <div onClick={handleSlideClick}>
                            <img src={swasthyam} style={{width:"100%",height:"100%"}}/>
                        </div>

                        <div onClick={handleSlideClick}>
                            <img src={swasthyam} style={{width:"100%",height:"100%"}}/>
                        </div>
                    </Slider>
                </div>
            </div>

            <div>
                <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",marginTop:"25%"}}>
                    <img src={familyImg} style={{width:"16%",height:"10%"}}/>
                    <img src={sriimg}  style={{width:"20%",marginTop:"3%"}}/>
                </div>

                <div style={{marginTop:"30px"}}>
                    <p style={{fontWeight:500,fontSize:"20px",textAlign:"center",lineHeight:"24px"}}>To Join Sri Family Guide Contact Us Here</p>
                </div>

                <div style={{}}>
                    <div>
                        <p style={{textAlign:"center"}}><span style={{fontWeight:600}}>Call to Know More:</span> 88888839082</p>
                        <p style={{textAlign:"center"}}><span style={{fontWeight:600}}>Write Us: </span>info@srifamilyguide.com</p>
                    </div>

                    <div>
                        <p style={{textAlign:"center"}}>
                            <span style={{fontWeight:600}}>Simances Learning LLP:</span>
                            <p>82/1A & 83/1A, Sakal Nagar, Gate no. 1, Baner Road, Anudh, Pune, Maharashtra(411007)</p>
                        </p>
                    </div>

                    <div>
                        
                    </div>
                </div>
            </div>

            <div style={{display:"flex", backgroundColor:"#000000",justifyContent:"center",alignItems:"center",padding:"5px",marginTop:"20%"}}>
                <span style={{color:"white"}}>Copyright Sakal Media Group</span>
            </div>
        </div>
    </>
  )
}

export default MainLandingPage
