import React, { Fragment } from 'react'
import "./Privacy.css"

const Privacy = () => {
  return (
    <div className='p-3 mb-3'>
      <div >
        <div className='text-center fw-bold mt-2'>
          <p>Privacy Policy</p>
        </div>
        <div></div>
        <div className='fw-bold'>
          <p>Our Privacy Policy as on ___________.</p>
        </div>
        <div></div>
        <div>
          <p>
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law protects
            You.
          </p>
        </div>
        <div></div>
        <div>
          <p>
            We use Your Personal data to provide and improve the Service and also to
            monetize. By using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>
        </div>
        <div></div>
        <div className='fw-bold'>
          <p>Interpretation and Definitions</p>
        </div>
        <div></div>
        <div className='fw-bold'>
          <p>Interpretation</p>
        </div>
        <div></div>
        <div>
          <p>
            The following definitions shall have the same meaning regardless of
            whether they appear in singular or plural.
          </p>
        </div>
        <div></div>
        <div className='fw-bold'>
          <p>Definitions</p>
        </div>
        <div></div>
        <div>
          <p>For the purposes of this Privacy Policy:</p>
        </div>
        <div></div>
        <div>
          <ul>
            <li>
              <p>
                <span className='fw-bold'>"Account"</span> means a unique account created for You to access our
                Service or parts of our Service.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div></div>
        <div>
          <ul>
            <li>
              <p>
                <span className='fw-bold'>"Application"</span> means the App / Website provided by the Company
                downloaded by You on any electronic device.
              </p>
            </li>
          </ul>
        </div>
        <div></div>
        <div>
          <ul>
            <li>
              <p><span className='fw-bold'>“Batch”</span> The Batch period shall be 52 weeks.</p>
            </li>
          </ul>
        </div>
        <div></div>
        <div>
          <ul>
            <li>
              <p>
                <span className='fw-bold'>"Company"</span> (referred to as either "the Company", "We", "Us" or "Our"
                in this Agreement) refers to SIMACES.
              </p>
            </li>
          </ul>
        </div>
        <div></div>
        <div>
          <ul>
            <li>
              <p>
                <span className='fw-bold'>"Cookies"</span> are small files that are placed on Your computer, mobile
                device or any other device by a website, containing the details of
                Your browsing history on that website among its many uses.
              </p>
            </li>
          </ul>
        </div>
        <div></div>
        <div>
          <ul>
            <li>
              <p>
                <span className='fw-bold'>"Device"</span> means any device that can access the Service such as a
                computer, a cell phone or a digital tablet.
              </p>
            </li>
          </ul>
        </div>
        <div></div>
        <div>
          <ul>
            <li>
              <p>
                <span className='fw-bold'>“Facilities”</span> means i) Weekly lectures, seminars, discussions etc.
                ii) Discourses of Guru’s through video iii) Access to the events
                organized by the SIMACES and Sakal Media Group. The Access may be
                paid or free as the case may be. iv) Tests to be conducted.
              </p>
            </li>
          </ul>
        </div>
        <div></div>
        <div>
          <ul>
            <li>
              <p>
                <span className='fw-bold'>"Personal Data"</span> is any information that relates to an identified or
                identifiable individual.
              </p>
            </li>
          </ul>
        </div>
        <div></div>
        <div>
          <ul>
            <li>
              <p><span className='fw-bold'>"Service"</span> refers to the App / Website.</p>
            </li>
            <li>
              <p>
                <span className='fw-bold'>"Service Provider"</span> means any natural or legal person who processes
                the data on behalf of the Company. It refers to third-party
                companies or individuals employed by the Company to facilitate the
                Service, to provide the Service on behalf of the Company, to perform
                services related to the Service or to assist the Company in
                analyzing how the Service is used.
              </p>
            </li>
          </ul>
        </div>
        <div></div>
        <div>
          <ul>
            <li>
              <p><span className='fw-bold'>‘Sri Family Guide’</span> means the course of 52 weeks</p>
            </li>
          </ul>
        </div>
        <div></div>
      </div>
      <div>
        <div>
          <ul>
            <li>
              <p>
                <span className='fw-bold'>"Usage Data"</span> refers to data collected automatically, either
                generated by the use of the Service or from the Service
                infrastructure itself (for example, the duration of a page visit,
                liking, or interest ).
              </p>
            </li>
          </ul>
        </div>
        <div></div>
        <div>
          <ul>
            <li>
              <p>
                <span className='fw-bold'>"You"</span> means the individual accessing or using the Service, or the
                company, or other legal entity on behalf of which such individual is
                accessing or using the Service, as applicable.
              </p>
            </li>
          </ul>
        </div>
        <div></div>
        <div className='fw-bold'>
          <p>Collecting and Using Your Personal Data</p>
        </div>
        <div></div>
        <div className='fw-bold'>
          <p>Types of Data Collected</p>
        </div>
        <div></div>
        <div className='fw-bold'>
          <p>Personal Data</p>
        </div>
        <div></div>
        <div>
          <p>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but is
            not limited to:
          </p>
        </div>
        <div></div>
        <div>
          <ul>
            <li>
              <p>Email address</p>
            </li>
            <li>
              <p>First name and last name</p>
            </li>
            <li>
              <p>Phone number</p>
            </li>
            <li>
              <p>Address, State, Province, ZIP/Postal code, City</p>
            </li>
            <li>
              <p>Interest / Liking / List of required products if any.</p>
            </li>
            <li>
              <p>Requirement of any product in the near future.</p>
            </li>
            <li>
              <p>Survey Questions</p>
            </li>
            <li >
              <p>Usage Data</p>
            </li>
          </ul>
        </div>
      </div>
      <div></div>
      <div className='fw-bold'>
        <p>Usage Data</p>
      </div>
      <div></div>
      <div>
        <p>Usage Data is collected automatically when using the Service.</p>
      </div>
      <div></div>
      <div>
        <p>
          Usage Data may include information such as Your Device's Internet Protocol
          address (e.g. IP address), browser type, browser version, the pages of our
          Service that You visit, the time and date of Your visit, the time spent on
          those pages, unique device identifiers and other diagnostic data.
        </p>
      </div>
      <div></div>
      <div>
        <p>
          When You access the Service by or through a mobile device, We may collect
          certain information automatically, including, but not limited to, the type
          of mobile device You use, Your mobile device's unique ID, the IP address
          of Your mobile / Tab / Electronic device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers and
          other diagnostic data.
        </p>
      </div>
      <div></div>
      <div>
        <p>
          We may also collect information that Your browser sends whenever You visit
          our Service or when You access the Service by or through a mobile device.
        </p>
      </div>
      <div></div>
      <div>
        <p>
          Participants authorized SIMACES for the sharing of personal data with
          third parties and monetize the same and participants herewith also gave
          their consent.
        </p>
      </div>
      <div></div>
      <div>
        <p>
          That SIMACES shall have exclusive rights over the data collected from this
          process.
        </p>
      </div>
      <div></div>
      <div>
        <p>
          Participants give rights over their personal data, such as the right to
          access their data, and the right to have their data corrected for the
          processing of their data.
        </p>
      </div>
      <div></div>
      <div></div>
      <div className='fw-bold'>
        <p>Information Collected while Using the Application</p>
      </div>
      <div></div>
      <div>
        <div>
          <p>
            While using Our Application, in order to provide features of Our
            Application, We may collect, with Your prior permission:
          </p>
        </div>
        <div>
          <ul>
            <li className='fw-bold'>
              <p>Information regarding your location</p>
            </li>
          </ul>
        </div>
        <div></div>
        <div>
          <p>
            We use this information to provide features of Our Service, to improve
            and customize Our Service. The information may be uploaded to the
            Company's servers and/or a Service Provider's server or it may be simply
            stored on Your device.
          </p>
        </div>
        <div></div>
        <div>
          <p>
            You can enable or disable access to this information at any time,
            through Your Device settings.
          </p>
        </div>
        <div></div>
        <div className='fw-bold'>
          <p>Use of Your Personal Data</p>
        </div>
        <div></div>
        <div>
          <p>The Company may use Personal Data for the following purposes:</p>
        </div>
        <div></div>
        <div>
          <ul>
            <li>
              <p>
                <span className='fw-bold'>To provide and maintain our Service,</span> including to monitor the usage
                of our Service.
              </p>
            </li>
            <li>
              <p>
                <span className='fw-bold'>To manage Your Account:</span> to manage Your registration as a user of the
                Service. The Personal Data You provide can give You access to
                different functionalities of the Service that are available to You
                as a registered user.
              </p>
            </li>
            <li>
              <p>
                <span className='fw-bold'>For the performance of a contract:</span> the development, compliance and
                undertaking of the purchase contract for the products, items or
                services You have purchased or of any other contract with Us through
                the Service.
              </p>
            </li>
            <li>
              <p>
                <span className='fw-bold'>To contact You:</span> To contact You by email, telephone calls, SMS, or
                other equivalent forms of electronic communication, such as a mobile
                application's push notifications regarding updates or informative
                communications related to the functionalities, products or
                contracted services, including the security updates, when necessary
                or reasonable for their implementation.
              </p>
            </li>
            <li>
              <p>
                <span className='fw-bold'>To provide You with news,</span> special offers and general information
                about other goods, services and events which we offer that are
                similar to those that you have already purchased or enquired about
                unless You have opted not to receive such information.
              </p>
            </li>
            <li>
              <p>
                <span className='fw-bold'>To manage Your requests:</span> To attend and manage Your requests to Us.
              </p>
            </li>
            <li>
              <p>
                <span className='fw-bold'>For business transfers:</span> We may use Your information to evaluate or
                conduct a merger, divestiture, restructuring, reorganization,
                dissolution, or other sale or transfer of some or all of Our assets,
                whether as a going concern or as part of bankruptcy, liquidation, or
                similar proceeding, in which Personal Data held by Us about our
                Service users is among the assets transferred.
              </p>
            </li>
            <li>
              <p>
                <span className='fw-bold'>For other purposes: </span>We may use Your information for other purposes,
                such as data analysis, identifying usage trends, determining the
                effectiveness of our promotional campaigns and evaluating and
                improving our Service, products, services, marketing and your
                experience.
              </p>
            </li>
          </ul>
        </div>
        <div></div>
      </div>
      <div>
        <div className='fw-bold'>
          <p>We may share Your personal information in the following situations:</p>
        </div>
        <div></div>
        <div>
          <ul>
            <li>
              <p>
                <span className='fw-bold'>With Service Providers:</span> We may share Your personal information with
                Service Providers to monitor and analyze the use of our Service, for
                payment processing, to contact You.
              </p>
            </li>
            <li>
              <p>
                <span className='fw-bold'>For business transfers:</span> We may share or transfer Your personal
                information in connection with, or during negotiations of, any
                merger, sale of Company assets, financing, or acquisition of all or
                a portion of Our business to another company.
              </p>
            </li>
            <li>
              <p>
                <span className='fw-bold'>With Affiliates: </span>We may share Your information with Our affiliates,
                in which case we will require those affiliates to honor this Privacy
                Policy. Affiliates include Our parent company and any other
                subsidiaries, joint venture partners or other companies that We
                control or that are under common control with Us.
              </p>
            </li>
            <li>
              <p>
                <span className='fw-bold'>With business partners:</span> We may share Your information with Our
                business partners to offer You certain products, services or
                promotions.
              </p>
            </li>
            <li>
              <p>
                <span className='fw-bold'>With other users:</span> When you share personal information or otherwise
                interact in public areas with other users, such information may be
                viewed by all users and may be publicly distributed outside.
              </p>
            </li>
            <li>
              <p>
                <span className='fw-bold'>With Your consent:</span> We may disclose Your personal information for any
                other purpose with Your consent.
              </p>
            </li>
          </ul>
        </div>
        <div></div>
        <div className='fw-bold'>
          <p>Retention of Your Personal Data</p>
        </div>
        <div></div>
        <div>
          <p>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply with
            our legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes, and enforce our
            legal agreements and policies.
          </p>
        </div>
        <div></div>
        <div>
          <p>
            The Company will also retain Usage Data for internal analysis purposes.
            Usage Data is generally retained for a shorter period of time, except
            when this data is used to strengthen the security or to improve the
            functionality of Our Service, or We are legally obligated to retain this
            data for longer time periods.
          </p>
        </div>
        <div></div>
        <div className='fw-bold'>
          <p>Transfer of Your Personal Data</p>
        </div>
        <div></div>
        <div>
          <p>
            Your information, including Personal Data, is processed at the Company's
            operating offices and in any other places where the parties involved in
            the processing are located. It means that this information may be
            transferred to — and maintained on — computers located outside of Your
            state, province, country or other governmental jurisdiction where the
            data protection laws may differ from those from Your jurisdiction.
          </p>
        </div>
        <div></div>
        <div>
          <p>
            Your consent to this Privacy Policy followed by Your submission of such
            information represents Your agreement to that transfer.
          </p>
        </div>
      </div>
      <div></div>
      <div>
        <p>
          The Company will take all steps reasonably necessary to ensure that Your
          data is treated securely and in accordance with this Privacy Policy and no
          transfer of Your Personal Data will take place to an organization or a
          country unless there are adequate controls in place including the security
          of Your data and other personal information.
        </p>
      </div>
      <div></div>
      <div className='fw-bold'>
        <p>Disclosure of Your Personal Data</p>
      </div>
      <div></div>
      <div className='fw-bold'>
        <p>Business Transactions</p>
      </div>
      <div></div>
      <div>
        <p>
          If the Company is involved in a merger, acquisition or asset sale, Your
          Personal Data may be transferred. We may not provide notice before Your
          Personal Data is transferred and becomes subject to a different Privacy
          Policy.
        </p>
      </div>
      <div></div>
      <div className='fw-bold'>
        <p>Law enforcement</p>
      </div>
      <div></div>
      <div>
        <p>
          Under certain circumstances, the Company may be required to disclose Your
          Personal Data if required to do so by law or in response to valid requests
          by public authorities (e.g. a court or a government agency). Laws of India
          shall be applicable.
        </p>
      </div>
      <div></div>
      <div className='fw-bold'>
        <p>Jurisdiction :</p>
      </div>
      <div></div>
      <div>
        <p>
          Jurisdiction for all legal cases will only be Pune &amp; all legal
          expenses shall be borne by the Participant and in case incurred/paid by
          SIMACES that shall be paid by the Participant to the SIMACES.
        </p>
      </div>
      <div></div>
      <div>
        <p>
          If any dispute, arises out of this then the same shall be resolved by the
          sole Arbitrator, and all the expenses of the Arbitration proceeding and or
          all legal proceedings shall be borne by the Participants. The seat of
          Arbitration shall be at Pune.
        </p>
        <div>
          <div className='fw-bold'>
            <p>Other legal requirements</p>
          </div>
          <div></div>
          <div>
            <p>
              The Company may disclose Your Personal Data in the good faith belief
              that such action is necessary to:
            </p>
          </div>
          <div></div>
          <div>
            <ul>
              <li>
                <p>Comply with a legal obligation</p>
              </li>
              <li>
                <p>Protect and defend the rights or property of the Company</p>
              </li>
              <li>
                <p>
                  Prevent or investigate possible wrongdoing in connection with the
                  Service
                </p>
              </li>
              <li>
                <p>
                  Protect the personal safety of Users of the Service or the public
                </p>
              </li>
              <li>
                <p>Protect against legal liability</p>
              </li>
            </ul>
          </div>
          <div></div>
          <div>
            <p><span className='fw-bold'>Security</span> of Your Personal Data</p>
          </div>
          <div></div>
          <div>
            <p>
              The security of Your Personal Data is important to Us, but remember
              that no method of transmission over the Internet, or method of
              electronic storage is 100% secure. While We strive to use commercially
              acceptable means to protect Your Personal Data, We cannot guarantee
              its absolute security.
            </p>
          </div>
          <div></div>
          <div className='fw-bold'>
            <p>Detailed Information on the Processing of Your Personal Data</p>
          </div>
          <div></div>
          <div>
            <p>
              The Service Providers We use may have access to Your Personal Data.
              These third-party vendors collect, store, use, process and transfer
              information about Your activity on Our Service in accordance with
              their Privacy Policies.
            </p>
          </div>
          <div></div>
          <div className='fw-bold'>
            <p>Analytics</p>
          </div>
          <div>
            <p>
              We may use third-party Service providers to monitor and analyze the
              use of our Service.
            </p>
          </div>
        </div>
        <div></div>
        <div className='fw-bold'>
          <p>Email Marketing</p>
        </div>
        <div></div>
        <div>
          <p>
            We may use Your Personal Data to contact You with newsletters, marketing
            or promotional materials and other information that may be of interest
            to You. You may opt-out of receiving any, or all, of these
            communications from Us by following the unsubscribe link or instructions
            provided in any email We send or by contacting Us.
          </p>
        </div>
        <div className='fw-bold'>
          <p>Children's Privacy</p>
        </div>
        <div></div>
        <div>
          <p>
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone under
            the age of 13. If You are a parent or guardian and You are aware that
            Your child has provided Us with Personal Data, please contact Us. If We
            become aware that We have collected Personal Data from anyone under the
            age of 13 without verification of parental consent, We take steps to
            remove that information from Our servers.
          </p>
        </div>
        <div>
          <div>
            <p>
              If We need to rely on consent as a legal basis for processing Your
              information and Your country requires consent from a parent, We may
              require Your parent's consent before We collect and use that
              information.
            </p>
          </div>
          <div></div>
          <div>
            <p>Links to Other Websites</p>
          </div>
          <div></div>
          <div>
            <p>
              Our Service may contain links to other websites that are not operated
              by Us. If You click on a third party link, You will be directed to
              that third party's site. We strongly advise You to review the Privacy
              Policy of every site You visit.
            </p>
          </div>
          <div></div>
          <div>
            <p>
              We have no control over and assume no responsibility for the content,
              privacy policies or practices of any third party sites or services.
            </p>
          </div>
          <div></div>
          <div>
            <p>Changes to this Privacy Policy</p>
          </div>
          <div></div>
          <div>
            <p>
              We may update Our Privacy Policy from time to time. We will notify You
              of any changes by posting the new Privacy Policy on this page.
            </p>
          </div>
          <div></div>
          <div>
            <p>
              We will let You know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the "Last
              updated" date at the top of this Privacy Policy.
            </p>
          </div>
          <div></div>
          <div>
            <p>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they are
              posted on this page.
            </p>
          </div>
          <div></div>
          <div className='fw-bold'>
            <p>Contact Us</p>
          </div>
          <div></div>
          <div>
            <p>
              If you have any questions about this Privacy Policy, You can contact
              us:
            </p>
          </div>
          <div></div>
          <div>
            <div>
              <ul>
                <li>
                  <p>
                    By visiting this page on our website:<span className='fw-bold'>[WEBSITE_CONTACT_PAGE_URL]</span>
                  </p>
                </li>
                <li>
                  <p>By sending us an email: <span className='fw-bold'>[WEBSITE_CONTACT_EMAIL]</span></p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div >

  );
}
export default Privacy
