import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import spiritualImg from "../../../Assets/spiritual.png"
import dateImg from "../../../Assets/Date.png"
import locationImg from "../../../Assets/Location.png"
import { useNavigate, useParams } from 'react-router-dom';
import Back from "../../../Assets/Back.svg";
import images from "../../../Assets/image 92.png";
import call from "../../../Assets/Group 33664.png";
import eventImg from "../../../Assets/MicrosoftTeams-image (1).png"
import { ApiCalling } from '../../../Services/Pool';
import PageNotFound from '../PageNotFound/PageNotFound';
import { showToastError, showToastSuccess, showToastWarning } from '../../../Utilities/ToastUtils';
import EventCard from './Scanner';
import useGetLabels from '../../CustomHookes/useGetLabels';
import SomethingWrong from '../PageNotFound/SomethingWrong';
import tick from "../../../Assets/tick.png"

const EventDetails = () => {
  const navigate = useNavigate()
  const { token, lang } = useParams()

  const [eventData, setEventData] = useState([])
  const [registerData, setRegiaterData] = useState([])
  const [helpnumber, setHelpNumber] = useState([])
  const [slotData, setSlotData] = useState([])

  const [loader, setLoader] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const [apiError, setApiError] = useState(false)

  const [selectedSlot, setSelectedSlot] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(slotData?.length <= 1);
  const [isSlotSelected, setIsSlotSelected] = useState(false);

  useEffect(() => {
    getEventData()
  }, [token])

  // for call custom hook
  const { getAllLabels, setApiLabelError, apiLabelError } = useGetLabels();

  let eneventLabels, hneventLabels, mreventLabels;

  if (getAllLabels !== undefined && getAllLabels !== null && getAllLabels.length > 0 && getAllLabels && getAllLabels.length > 0) {
    const { en, hn, mr } = getAllLabels[0];

    if (en && hn && mr) {
      eneventLabels = en.eventLabels;
      hneventLabels = hn.eventLabels;
      mreventLabels = mr.eventLabels;
    }
  }


  // getevent details
  const getEventData = async () => {
    setLoader(true)
    try {
      const response = await fetch(
        `${ApiCalling.base_url_v1}${ApiCalling.paths.getEventForUser}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json", "authorization": token },
        }
      );
      const responseData = await response.json();

      if (responseData) {
        setEventData(responseData?.eventData)
        setRegiaterData(responseData?.registerData)
        setSlotData(responseData?.slots)
        setHelpNumber(responseData?.helpNumbers)
        setLoader(false)
      }
    }
    catch (error) {
      setLoader(false)
      setApiError(true)
      setApiLabelError(true)
      console.log(error)
    }
  }


  // book event
  const handleBookEvent = async (eventID, selectedSlot) => {
    console.log("booked");
    setLoader(true)
    try {
      const payload = {
        eventId: eventID,
        slot: [
          {
            "_id": selectedSlot?._id,
            "batch": selectedSlot?.batch,
            "date": selectedSlot?.date,
            "hallNumber": selectedSlot?.hallNumber,
            "slots": selectedSlot?.slots,
            "bookingLimit": selectedSlot?.bookingLimit,
            "booked": selectedSlot?.booked
          }
        ]
      }
      const response = await fetch(

        `${ApiCalling.base_url_v1}${ApiCalling.paths.bookEvent}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json", "authorization": token },
          body: JSON.stringify(payload)
        }
      );
      const responseData = await response.json();
      setLoader(false)
      if (responseData?.message === 'Already Registered') {
        setLoader(false)
        showToastWarning("Event Already Booked.")
        setShowAdd(false)
      } else if (responseData?.message === 'Register Successfully') {
        setLoader(false)
        showToastSuccess("Event Booked Succassfully !")
        setShowAdd(false)
        getEventData()
        setTimeout(() => {
          var qrCodeContainer = document.getElementById('qrCodeContainer');
          if (qrCodeContainer) {
            qrCodeContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 1000);
      }
    } catch (error) {
      setApiError(true)
      console.log("error")
    }
  }

  // useEffect(() => {
  //   if (slotData.length === 1) {
  //     setSelectedSlot(slotData[0]);
  //     setIsSlotSelected(true);
  //     setIsButtonDisabled(false);
  //   }
  // }, [slotData]);

  const handleModal = () => {
    if (isSlotSelected) {
      setShowAdd(!showAdd);
    } else {
      showToastWarning("Please select a slot first.");
    }
  };

  // date conversion
  const getFormattedDate = (date) => {
    if (!date) {
      console.error("Invalid date:", date);
      return null;
    }

    const [day, month, year] = date.split('-').map(Number);

    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      console.error("Invalid date components:", date);
      return null;
    }

    const parsedDate = new Date(year, month - 1, day);

    if (isNaN(parsedDate.getTime())) {
      console.error("Invalid parsed date:", date);
      return null;
    }

    const formattedDate = parsedDate.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
    return formattedDate;
  };

  // for click slot
  // const handleSlotClick = (ele) => {
  //   setSelectedSlot(ele);
  //   setIsSlotSelected(true);
  //   setIsButtonDisabled(false);
  // };

  const handleSlotClick = (ele) => {
    if (registerData?.status !== "Confirmed") {
      setSelectedSlot(ele);
      setIsSlotSelected(true);
      setIsButtonDisabled(false);
    }
    // else {
    //   // setIsButtonDisabled(true);
    //   showToastWarning("Cannot select slot.Event already booked.");
    // }
  };



  return (
    <>
      <div className='mt-3'>
        {
          loader ?
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spinner size='lg' />
            </div>
            :
            apiError || apiLabelError ?
              <SomethingWrong /> :
              <>
                <div className='mainContainer' style={{ overflowX: 'scroll', height: '80vh' }}>
                  {
                    registerData?.qrLink &&
                    <>
                      <div style={{ display: "flex", justifyContent: "end", marginRight: "5px" }}>
                        <button style={{ backgroundColor: "#FD6E47", color: "white", border: "none", padding: "5px 10px", borderRadius: "10px" }}
                          onClick={() => navigate('/scanner', { state: { scanner: registerData?.qrLink, uuid: registerData?.UUId, token: token, lang: lang } })}>
                          <Icon.QrCodeScan className='me-2' />View QR</button>
                      </div>
                    </>
                  }
                  <div className='imageContainer'>
                    <img src={eventData?.image} alt='image' style={{ width: "100%", borderRadius: "20px", marginTop: "10px" }} />
                  </div>

                  <div className='eventContainer' >
                    <p style={{ fontSize: "18px", fontWeight: 400, lineHeight: "28.8px" }}>
                      {lang === "mr" ? eventData?.mar?.eventName : lang === "hn" ? eventData?.hn?.eventName : eventData?.en?.eventName}
                    </p>
                    <div style={{ display: "flex", alignItems: "center", marginTop: "5%" }}>
                      <img src={dateImg} className='contentImg' />
                      <div>
                        <h6 style={{ fontSize: "14px", fontWeight: 400, margin: 0 }}>
                          {getFormattedDate(eventData?.StartDate)} - {getFormattedDate(eventData?.EndDate)}
                        </h6>
                      </div>
                    </div>

                    <div style={{ display: "flex", marginTop: "20px" }}>
                      <img src={locationImg} className='contentImg' />
                      <div>
                        <h6 style={{ fontSize: "14px", fontWeight: 400, lineHeight: "19.07px" }}>{lang === "mr" ? eventData?.mar?.address : lang === "hn" ? eventData?.hn?.address : eventData?.en?.address}</h6>
                        <a href={eventData?.locationUrl} target="_blank" rel="noopener noreferrer">
                          Take me to the location
                        </a>
                      </div>
                    </div>

                    <div className="mt-3">
                      <h6 className="slotHeading" >{registerData?.status === "Confirmed" ? "Your slot is booked for" : "Book your slot now"}</h6>
                      <div className='d-flex justify-content-between,align-items-center'>
                        {slotData && slotData.map((ele, index) => (
                          <div
                            key={index}
                            onClick={() => handleSlotClick(ele)}
                            className={`mx-1 slotCard ${selectedSlot === ele ? 'selected' : ''} ${registerData?.status === "Confirmed" ? "slotDisabledBtn" : ""}`}
                          >
                            <div className='d-flex justify-content-between align-items-center'>
                              <h6 className="slotCardh6 m-0 p-0 ">{`Batch ${ele?.batch}`}</h6>
                              {registerData?.status === "Confirmed" && <img src={tick} />}
                            </div>
                            <div className='mt-2'>
                              <p className="m-0 p-0" style={{ fontSize: '12px', color: '#353534' }}>{ele?.slots}</p>
                              <p className="m-0 p-0" style={{ fontSize: '10px', color: '#353534' }}>{ele?.date}</p>
                            </div>
                          </div>
                        ))}
                      </div>

                    </div>

                    <div style={{ backgroundColor: "#EBF3FC", padding: "15px", borderRadius: "10px", marginTop: "15px" }}>
                      <h6 style={{ fontSize: "16px", fontWeight: 600, lineHeight: "34px" }}>
                        {lang === "mr" ? mreventLabels?.aboutEvent : lang === "hn" ? hneventLabels?.aboutEvent : eneventLabels?.aboutEvent}
                      </h6>


                      <div>
                        <p style={{ fontSize: "14px", fontWeight: 400, lineHeight: "22px" }}>
                          {lang === "mr" ? eventData?.mar?.description : lang === "hn" ? eventData?.hn?.description : eventData?.en?.description}
                        </p>
                      </div>
                    </div>

                    <div>
                      <h6 style={{ margin: "15px 0px 15px 0px", fontSize: "16px" }}>
                        {lang === "mr" ? mreventLabels?.anyHelp : lang === "hn" ? hneventLabels?.anyHelp : eneventLabels?.anyHelp}
                      </h6>

                      <div style={{}}>
                        {
                          helpnumber !== undefined &&
                          helpnumber !== null &&
                          helpnumber.length > 0 &&
                          helpnumber?.map((data, index) => {
                            return (
                              <>
                                <div style={{ display: "flex", marginTop: "10px" }}>
                                  <img src={call} className='contentImg' />
                                  <div>
                                    <h6 style={{ fontSize: "16px", fontWeight: 600 }}>{data.name}</h6>
                                    <h6 style={{ fontSize: "14px", fontWeight: 400 }}>{data.phoneNumber}</h6>
                                  </div>
                                </div>
                              </>
                            )
                          })
                        }
                      </div>
                    </div>

                  </div>
                </div>

                <div className='d-flex justify-content-center my-4'
                  style={{
                    width: "90%", display: "flex", justifyContent: "center", marginTop: "30px", marginBottom: "40px",
                    position: "fixed", bottom: 0, left: "20px"
                  }}
                  onClick={() => { handleModal() }}
                >

                  <button
                    className={registerData?.status === "Confirmed" || isButtonDisabled ? "disabledBtn" : "primaryBtn"}
                    disabled={registerData?.status === "Confirmed" ? true : false}
                  >
                    {(registerData?.status === "Confirmed")
                      ? lang === "mr"
                        ? mreventLabels?.EventBooked
                        : lang === "hn"
                          ? hneventLabels?.EventBooked
                          : eneventLabels?.EventBooked

                      : lang === "mr"
                        ? mreventLabels?.BookEvent
                        : lang === "hn"
                          ? hneventLabels?.BookEvent
                          : eneventLabels?.BookEvent}
                  </button>
                </div>
              </>
        }
      </div>

      {/* Book event model */}
      <Modal size='sm' show={showAdd} backdrop='static' keyboard={false} centered onHide={() => { handleModal() }}>
        <Modal.Header closeButton>
          <Modal.Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <h5>
                {lang === "mr" ? mreventLabels?.confirmation : lang === "hn" ? hneventLabels?.confirmation : eneventLabels?.confirmation}
              </h5>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {
            loader ?
              <><Spinner size='sm' /></> :
              apiError ?
                "Something went wrong" :
                <>
                  <div style={{ width: "100%", display: "flex", justifyContent: "space-around" }}>
                    <Button style={{
                      backgroundColor: "transparent", color: "#FD6E47", border: "1px solid #FD6E47", borderRadius: "20px",
                      padding: "5px 25px"
                    }} disabled={loader} onClick={() => handleModal()}>
                      {lang === "mr" ? mreventLabels?.cancel
                        : lang === "hn" ? hneventLabels?.cancel
                          : eneventLabels?.cancel}
                    </Button>
                    <Button style={{
                      backgroundColor: "#FD6E47", color: "white", border: "1px solid #FD6E47", borderRadius: "20px",
                      padding: "5px 25px"
                    }} disabled={loader} onClick={() => handleBookEvent(eventData?._id, selectedSlot)}>
                      {lang === "mr" ? mreventLabels?.confirm
                        : lang === "hn" ? hneventLabels?.confirm
                          : eneventLabels?.confirm
                      }
                    </Button>
                  </div>
                </>

          }

        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EventDetails
